@forward "components/theme";
@forward "../css/font-awesome-pro.css";
@forward "../css/animate.css";
@forward "../css/ui-icon.css";
@forward "../css/pe-icon-7-stroke.css";
@forward "components/back-to-top";
@forward "components/background";
@forward "components/spacing";
@forward "components/buttons";
@forward "components/carousel";
@forward "components/modal";
@forward "components/section-title";
@forward "components/breadcrumb";
@forward "components/animation";
@forward "components/accordion";
@forward "components/preloader";
@forward "components/calendar";
@forward "layout/header";
@forward "layout/meanmenu";
@forward "layout/slider";
@forward "layout/service";
@forward "layout/about";
@forward "layout/gallery";
@forward "layout/team";
@forward "layout/feature";
@forward "layout/testimonial";
@forward "layout/portfolio";
@forward "layout/promotion";
@forward "layout/project";
@forward "layout/price";
@forward "layout/blog";
@forward "layout/contact";
@forward "layout/footer";

// .text-light-custom {
//     color: var(--tp-text-2);
// }

.arrow-btn-group {
    margin-left: auto;

    & .arrow-btn {
        width: 40px;
        height: 40px;
        border: 1px solid var(--tp-common-black);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            font-size: 20px;
        }
    }
}

.btn-common-light {
    background-color: var(--tp-grey-2);
    color: var(--tp-common-black);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
    padding: 2px 21px;
    border: 1px solid var(--tp-border-2);
    font-family: var(--tp-ff-heading);

    &:hover {
        background: var(--tp-common-black);
        color: var(--tp-common-white);
    }
}

.booking-form-input {
    input {
        // margin-right: 30px;
        height: 50px;
        // margin-bottom: 20px;
        // padding-left: 20px;
        // padding-right: 30px;
        padding: 10px;
        background-color: var(--tp-grey-3);
        border: 0;
        width: 100%;
        border-radius: 5px;
    }

    textarea {
        padding: 10px;
        background-color: var(--tp-grey-3);
        border: 0;
        width: 100%;
        border-radius: 5px;
    }
}

.booking-policy {
    li {
        list-style: initial;
    }
}

.hotel-logo {
    max-width: 80px;
}

.booking-status {
    .title {
        width: 100px;
        font-weight: bold;
        color: var(--tp-common-black);
    }

    .detail {
        color: var(--tp-text-1);
    }
}

.search-booking-datepicker {
    margin-right: 20px !important;
}

.home-section-1 {
    padding-top: 0;

    @media (min-width: 768px) {
        padding-top: 50px;
    }
}

.nav-logo {
    @media (max-width: 768px) {
        max-width: 200px !important;
    }
}

.youtube-container {
    width: 100%;
    max-width: 700px;
}

.image-thumb-with-preview {
    img {
        width: 100%;
    }

    position: relative;
    overflow: hidden;

    & img {
        &:hover {
            transform: scale(1.1);
        }
    }

    .image-preview-span {
        position: absolute;
        opacity: 0.7;
        top: 10px;
        right: 10px;
        color: var(--tp-common-white);
        background-color: var(--tp-common-black);
        padding: 10px;
        font-size: 16px;
    }

    .price-span {
        position: absolute;
        bottom: 0;
        left: 10px;
        color: var(--tp-common-white);
        background-color: var(--tp-common-black);
        padding: 7px 25px;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.18em;
        text-transform: uppercase;
        font-family: var(--tp-ff-heading);
    }

    .not-avar-span {
        position: absolute;
        bottom: 0;
        right: 10px;
        color: var(--tp-common-white);
        background-color: red;
        padding: 7px 10px;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.18em;
        text-transform: uppercase;
        font-family: var(--tp-ff-heading);
    }
}

@keyframes spin3D {
    from {
        transform: rotateY(0deg);
    }
    to {
        transform: rotateY(360deg);
    }
}

.icon-rotate-x {
    animation: spin3D 3s ease-in-out infinite;
}

.custom-dateinput-search {
    border: 1px solid var(--tp-common-black);
    border-radius: 30px;

    p {
        color: var(--tp-common-black);
        font-size: 12px;
        text-align: left;
    }

    .checktime-info {
        width: 100px;
    }

    .display-date {
        height: 20px;
        margin-bottom: 5px;
        color: var(--tp-common-black);
        text-align: left;
    }
}

.react-datepicker__close-icon::after {
    background-color: var(--tp-common-black) !important;
}

.hide-rooms {
    p {
        color: var(--tp-common-black);
        font-size: 12px;
        text-align: left;
    }
}

.react-calendar {
    width: 400px !important;
    color: #000000;
    border-color: #000000;

    p {
        color: #000000;
    }
}

.booking-calendar-price {
    margin-bottom: 0;
    font-size: 8px;
}

.booking-calendar-date {
    .normal-price-small {
        font-size: 7px;
        color: #ababab;
        text-decoration: line-through;
    }

    &.react-calendar__month-view__days__day--weekend {
        color: #000000;
    }

    &.custom-weekend {
        color: #d10000;

        p {
            color: #d10000;
        }
    }

    &.custom-holiday {
        color: #ff7725;

        p {
            color: #ff7725;
        }

        .normal-price-small {
            color: #ff7725;
            opacity: 0.5;
        }
    }

    &.react-calendar__tile {
        &--now {
            background-color: #878787 !important;
            color: #ffffff !important;

            .booking-calendar-price {
                color: #ffffff !important;
            }

            .normal-price-small {
                color: #ffffff !important;
            }
        }

        &--active,
        &--hasActive {
            background-color: #000000 !important;
            color: #ffffff !important;

            .booking-calendar-price {
                color: #ffffff !important;
            }

            .normal-price-small {
                color: #ffffff !important;
            }
        }

        &:disabled {
            .booking-calendar-price {
                color: #ababab;
            }
        }
    }

    &.custom-booked {
        background-color: #f0f0f0;
        color: #ababab;

        p {
            color: #ababab;
        }
    }

    &.custom-close-all {
        background-color: navy;
        color: #ffffff;

        p {
            color: #ffffff;
        }
    }

    &.custom-has-booking {
        background-color: darkcyan;
        color: #ffffff;

        p {
            color: #ffffff;
        }
    }

    &.custom-close-room {
        background-color: #ff6f6f !important;
        color: #ffffff !important;

        abbr {
            text-decoration: line-through;
        }

        p,
        .normal-price-small {
            color: #ffffff !important;
        }
    }

    &.custom-price {
        padding: 0;
    }

    &.part-date {
        padding-top: 20px;
        padding-bottom: 20px;

        .price-container {
            display: none;
        }
    }

    &.custom-part-date {
        opacity: 0.5;
    }
}

.progress-wrap {
    bottom: 100px;
}

.sm-calendar {
    .title-btn {
        font-size: 12px;
    }

    .booking-calendar-price {
        font-size: 6px !important;
    }

    .react-calendar__tile {
        padding-top: 5px;
        padding-bottom: 5px;

        &.custom-price {
            padding: 0;
        }

        &.part-date {
            padding-top: 18px;
            padding-bottom: 18px;
        }
    }
}

.deposit-booking-form {
    .form-check-input:checked {
        background-color: $black;
        border-color: $black;
    }
}

.react-select-custom__control,
.react-select-custom__value-container {
    height: 44px;
}

.react-select-custom__input-container {
    height: 38px;

    .react-select-custom__input {
        height: 38px;
    }
}

.react-calendar {
    font-family: var(--tp-ff-body) !important;
}

.camp-type-select-warning {
    .react-select-custom__placeholder {
        color: red;
    }

    .react-select-custom__control {
        border: 1px solid red;
    }
}

.ReactModal__Overlay {
    z-index: 99999 !important;
}

.custom-room-title {
    margin-bottom: -5px;
    margin-top: 15px !important;
}

.room-qty-title {
    span {
        font-size: 14px !important;
    }
}
